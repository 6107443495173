import { graphql, useStaticQuery } from 'gatsby';

export const useMetricsGraphs = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpMetricsGraph {
        nodes {
          ...GeneratedWpMetricsGraph
        }
      }
    }
  `);

  return data.allWpMetricsGraph?.nodes;
};
