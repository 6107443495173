import { graphql, Link } from 'gatsby';
import React, { useState, useRef, useEffect } from 'react';
import Layout from './Layout';
import { LayoutRenderer } from '~/components/layouts/LayoutRenderer';

import resourceMedia from '~/assets/images/resource-media.png';

import Seo from 'gatsby-plugin-wpgraphql-seo';
import { useMetricsGraphs } from '~/hooks/useMetricsGraphs';
import metricGridIcon from '~/assets/icons/metric-masthead-graph.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { CategoryLine, MetricsGrid } from '../components/landing/Metrics';
import { useMetrics } from '../hooks/useMetrics';
import { ArrowLink, Button, MaybeLink } from '../components/elements/Link';
import Image from '../components/elements/Image';
import Loader from '~/assets/icons/puff.svg';

export default function MetricPage({ data: { wpFinancialMetric, wp, resourcesPage } }) {
  const { metrics, categories } = useMetrics();

  let relatedCategory = categories?.filter(
    (cat) => cat.slug === wpFinancialMetric?.financialMetricsImported?.categoryReference
  );
  const metricsImported = wpFinancialMetric.financialMetricsImported;

  return (
    <Layout wp={wp}>
      <Seo post={wpFinancialMetric} />

      <section className="pb-12 md:pb-24 bg-lightGrey section-top">
        <div className="container pt-8 space-y-4 text-center">
          <img className="mx-auto mb-5 md:mb-10" src={metricGridIcon} alt="" />
          <h1 className=" t-subheading-plain">{metricsImported?.stringCategoryReference}</h1>
          <h2 className="t-54 max-w-[540px] mx-auto">{metricsImported?.name || wpFinancialMetric?.title}</h2>
          <p className="t-20 max-w-[505px] mx-auto">{metricsImported?.description}</p>
        </div>
      </section>

      <div className="container justify-between pt-12 md:space-x-6 md:flex">
        <MetricSidebar
          categories={categories}
          categoryReference={metricsImported?.categoryReference}
          graphs={metricsImported.graphs}
        />

        <div className="max-w-[588px]">
          <MetricPageGraphs graphString={metricsImported.graphs} />
        </div>
        <CategoryBox
          category={metricsImported?.stringCategoryReference}
          description={metricsImported?.sidebarDescription}
        />
      </div>

      <div className="container flex justify-end py-12 md:mt-36 md:mb-[100px]">
        <RelatedMetrics metrics={relatedCategory[0]?.metrics} category={relatedCategory[0]?.slug} />
      </div>
      {metricsImported.relatedBlogPosts && <RelatedPosts posts={metricsImported.relatedBlogPosts} />}

      <div className="mt-32">
        <LayoutRenderer
          prefix="Page_Posttypepage_Sections"
          sections={resourcesPage?.postTypePage?.sections?.filter(
            (section) => section?.fieldGroupName === 'Page_Posttypepage_Sections_ReusableBlock'
          )}
        />
      </div>
    </Layout>
  );
}

export const RelatedMetrics = ({ metrics, category }) => {
  return (
    <div className="max-w-[897px] w-full">
      <h3 className="t-36 ">Related Metrics</h3>

      <div className="mt-6 md:mt-10">
        <MetricsGrid metrics={metrics?.slice(0, 3)} />{' '}
      </div>

      <div className="mt-10">
        <Link to={`/financial-metrics/#${category}`} className="group">
          <ArrowLink link={{ title: 'View all' }} />
        </Link>
      </div>
    </div>
  );
};

// TODO: preview excerpt and author will need hooked up

export const RelatedPosts = ({ posts }) => {
  return (
    <div className="bg-tertiaryGrey py-12 md:py-[100px]">
      <div className="container">
        <h3 className="t-36 ">Related Blog Posts</h3>

        <div className="mt-6 md:mt-10 grid md:grid-cols-2 lg:grid-cols-4 gap-[21px]">
          {posts?.map((post, i) => (
            <motion.div
              key={`post${i}`}
              whileHover={{ x: -5, y: -5 }}
              transition={{ duration: 0.3 }}
              className="lg:max-w-[285px] w-full border-default-grid bg-white flex flex-col transition-shadow duration-150 hover:shadow-largeAsset"
            >
              <Link to={post?.uri} className="flex flex-col flex-auto">
                <div className="relative w-full border-b bg-tertiaryGrey aspect-w-282 aspect-h-165 image-absolute border-strokeGrey">
                  <Image
                    objectFit="cover"
                    className="w-full "
                    image={post?.postTypePost?.previewImage || resourceMedia}
                  />
                </div>

                <div className="flex flex-col flex-auto p-5 bg-white ">
                  <div className="mb-6 space-y-4">
                    <div className="mt-4 font-semibold t-12 uppercase bg-lightGrey t-1 py-[7px] px-[9px] max-w-max">
                      {post?.categories?.nodes[0]?.name}
                    </div>
                    <h5 className="font-medium t-20 leading-[1.32]">{post?.title}</h5>
                    <p className="t-15">
                      A look at one of the most important metrics you can measure to understand how your customers are
                      feeling about you.
                    </p>
                  </div>
                  <div className="flex mt-auto space-x-[8px] items-center">
                    <div className="w-[22px] h-[22px] bg-strokeGrey"></div>

                    <div className="t-12">
                      <span className="font-medium">Dylan Jensen, </span>Chief Operating Officer
                    </div>
                  </div>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>

        <div className="mt-6 md:mt-10">
          <Link to={`/resources`} className="group">
            <ArrowLink link={{ title: 'View all' }} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export const CategoryBox = ({ category, description }) => (
  <div className="md:max-w-[223px] w-full mt-12 md:mt-0">
    <div className="w-full px-4 py-6 border border-strokeGrey">
      <div className="mx-auto font-medium text-center t-15 md:mx-0 md:text-left">Category</div>
      <div className="mt-4 font-semibold t-12 uppercase bg-lightGrey t-1 py-[7px] px-[9px] mx-auto md:mx-0  max-w-max">
        {category}
      </div>
    </div>

    <div className="mt-4 md:mt-8">
      <MaybeLink link={{ url: '/request-a-demo' }}>
        <Button size="fullWidth" link={{ title: 'Start using this metric' }} buttonStyle="primary" />
      </MaybeLink>
    </div>

    <div className="mt-8">
      <div className="font-medium text-left t-15">Description</div>

      <div className="mt-2" dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  </div>
);

export const MetricSidebar = ({ categories, categoryReference, graphs }) => {
  const [activeCategory, setActiveCategory] = useState(null);

  return (
    <div className="md:w-[172px] mb-6 md:mb-0 md:mr-8 lg:mr-12  relative hidden lg:block">
      <div className="sticky top-[10rem]">
        <h3 className="mb-6 t-24">Categories</h3>
        <ul className="hidden space-y-3 font-medium t-15 md:block">
          <motion.li className="relative cursor-pointer" whileHover="hover" initial="rest">
            <CategoryLine />
            <Link to={`/financial-metrics`}>All</Link>
          </motion.li>
          {categories.map((category, q) => {
            let activeType;

            if (activeCategory === null) {
              activeType = categoryReference === category.slug;
            } else {
              activeType = q === activeCategory;
            }

            return (
              <motion.li key={q} className="" whileHover="hover" initial="rest">
                <div onClick={() => setActiveCategory(q)} className="relative cursor-pointer ">
                  <CategoryLine i={q + 1} active={activeType} />
                  {category.tabTitle}
                </div>
                <AnimatePresence initial={false}>
                  {activeType &&
                    category?.metrics?.map(
                      (metric, i) =>
                        metric?.financialMetricsImported?.graphs && (
                          <motion.div
                            key={i}
                            initial={{ opacity: 0, x: 10 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: i / 14, type: 'spring' }}
                          >
                            <Link
                              to={metric?.uri}
                              key={`childMetric${i}`}
                              activeClassName="!text-primaryBlue"
                              className="text-secondaryText"
                            >
                              <div
                                className={`${
                                  i === category?.metrics?.length - 1 && 'mb-2'
                                } pl-4 font-normal cursor-pointer hover:text-primaryBlue t-14 leading-[1.2] pt-3  `}
                              >
                                {metric?.title}
                              </div>
                            </Link>
                          </motion.div>
                        )
                    )}
                </AnimatePresence>
              </motion.li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export const MetricPageGraphs = ({ graphString }) => {
  const graphs = filterGraphs(useMetricsGraphs(), graphString);
  if (!graphs) return null;
  const [scriptLoaded, setScriptLoaded] = useState(0);

  useEffect(() => {
    if (typeof document === 'undefined') return;
    if (typeof window === 'undefined' || window.legendOptions) return;
    let cancelled = false;

    ['https://cdn.jsdelivr.net/npm/chart.js@2.9.4/dist/Chart.min.js', '/legacy_chart.js'].map((src) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = () => {
        if (cancelled) return;
        setScriptLoaded((loaded) => {
          return loaded + 1;
        });
      };
      document.body.appendChild(script);
    });

    return () => {
      cancelled = true;
    };
  }, []);

  return (
    <div className="space-y-12">
      {graphs.map((graph, i) => (
        <MetricsGraph scriptLoaded={scriptLoaded === 2} key={i} {...graph} />
      ))}
    </div>
  );
};

const filterGraphs = (graphs, string) => {
  if (!string) return [];
  const results = [];
  const slugs = string.split(';');
  graphs.map((graph) => {
    slugs.map((slug) => {
      if (graph.metricsGraphImported.slug == slug.trim()) {
        results.push(graph);
      }
    });
  });
  return results;
};

export const MetricsGraph = ({ scriptLoaded, metricsGraphImported }) => {
  const ref = useRef();
  const {
    name,
    previewDescription,
    previewData,
    previewType,
    previewDataLabels,
    previewMainDataName,
    previewSecondaryDataName,
    startingCohortColumn,
    unit,
    secondaryUnit,
  } = metricsGraphImported;

  useEffect(() => {
    if (!scriptLoaded || !ref.current) return;
    render_legacy_chart({
      graphType: previewType,
      canvasEl: previewType !== 'Table' ? ref.current.getContext('2d') : null,
      tableEl: ref.current,
      labels: previewDataLabels,
      name: name,
      mainName: previewMainDataName,
      overlayName: previewSecondaryDataName,
      data: previewData,
      unit: unit,
      secondaryUnit: secondaryUnit,
      startCohortColumn: startingCohortColumn,
    });
  }, [scriptLoaded, ref]);

  return (
    <div className="bg-white border border-[#D5D9E7] p-8 graph-shadow ">
      <h5 className="mb-3 font-medium t-20">{name}</h5>
      <p>{previewDescription}</p>
      <div className="mt-8">
        {scriptLoaded ? (
          previewType === 'Table' ? (
            <div ref={ref}></div>
          ) : (
            <canvas ref={ref} id="stacked-line-a" width="100%"></canvas>
          )
        ) : (
          <img className="mx-auto" src={Loader} alt="" />
        )}
      </div>
    </div>
  );
};

export const pageQuery = graphql`
  query Metric($id: String!) {
    wpFinancialMetric(id: { eq: $id }) {
      ...GeneratedWpFinancialMetric
      ...FinancialMetricSEO
    }
    resourcesPage: wpPage(id: { eq: "cG9zdDozMzU=" }) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
  }
`;
